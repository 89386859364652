import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Alert } from './interfaces';


/**
 * Provides methods for managing the layout of the app.
 */
@Injectable({
	providedIn: 'root',
})
export class LayoutService {
	/**
	 * The list of all application-level alerts.
	 */
	private alerts: Alert[] = [];
	/**
	 * The an observable of the list of alerts.
	 */
	private alertsSubject = new BehaviorSubject<Alert[]>([]);


	// ------ GETTERS -------
	/**
	 * Get the alerts.
	 *
	 * @return The the subset of alerts that are floating alerts
	 */
	public get floatingAlerts(): Observable<Alert[]> {
		return this.alertsSubject.pipe(
			map(alerts => alerts.filter(alert => alert.location === 'floating')),
		);
	}


	// ------ ALERT MANAGEMENT ------
	/**
	 * Ad an alert to the application.
	 *
	 * @param alert The alert to add.
	 * @return The added alert.
	 */
	public addAlert(alert: Alert): Alert {
		// Resolve the alert object
		const location = alert.location || 'floating';
		const defaultAlert = {
			type: 'info',
			location: 'floating',
			dismissable: true,
			timeout: location === 'floating' ? 5000 : null,
		};
		alert = Object.assign(defaultAlert, alert);

		// Add the alert to the alerts array
		this.alerts.push(alert);
		this.updateAlertsSubject();

		// Show the alert in the console
		const methodMap = {
			error: console.error,
			warning: console.warn,
			info: console.info,
		};
		const method = methodMap[alert.type] || console.info;
		method(alert.content);

		// Set the timer on the alert
		if (alert.timeout && alert.timeout > 0) {
			this.timeoutAlert(alert, alert.timeout);
		}

		// Return the alert
		return alert;
	}

	/**
	 * Remove an alert from the application.
	 *
	 * @param alert The alert to remove.
	 */
	public removeAlert(alert: Alert): void {
		this.alerts.splice(this.alerts.indexOf(alert), 1);
		this.updateAlertsSubject();
	}

	/**
	 * Set a timeout function to remove an alert.
	 *
	 * @param alert The alert to timeout.
	 * @param timeout The time in milliseconds to timeout.
	 */
	private timeoutAlert(alert: Alert, timeout: number): void {
		setTimeout(() => {
			this.removeAlert(alert);
		}, timeout);
	}

	/**
	 * Update the alerts subject
	 */
	private updateAlertsSubject(): void {
		this.alertsSubject.next(this.alerts);
	}
}

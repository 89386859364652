import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

/**
 * The component for the information page.
 */
@Component({
	selector: 'mc-info',
	templateUrl: './events.component.html',
	styleUrls: ['./events.component.scss']
})
export class EventComponent implements OnInit {
	/**
	 * The ID of the event to showcase.
	 */
	eventId = 79216824655;

	constructor(private sanitizer: DomSanitizer) { }

	/**
	 * Initialize the component.
	 */
	ngOnInit() {
		if (window.EBWidgets) {
			window.EBWidgets.createWidget({
				widgetType: 'checkout',
				eventId: this.eventId,
				iframeContainerId: `eventbrite-widget-container-${this.eventId}`,
				// Optional
				iframeContainerHeight: 425, // Widget height in pixels. Defaults to a minimum of 425px if not provided
			});
		}
	}

	/**
	 * Defines the URL for the calendar.
	 */
	get calendarUrl(): SafeResourceUrl {
		return this.sanitizer.bypassSecurityTrustResourceUrl(`https://www.eventbrite.com/calendar-widget?eid=${this.eventId}`);
	}
}

import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { ContactService } from './contact.service';
import { environment } from 'src/environments/environment.prod';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { LayoutService } from 'src/app/layout';

/**
 * The component for the contact page.
 */
@Component({
	templateUrl: './contact.component.html',
	styleUrls: ['./contact.component.scss']
})
export class ContactComponent {
	/**
	 * Whether to show the button as loading.
	 */
	public buttonLoading = false;
	/**
	 * The form definition
	 */
	public form: FormGroup = this.fb.group({
		name: ['', [Validators.required]],
		company: [''],
		email: ['', [Validators.required, Validators.email]],
		message: ['', Validators.required],
		newsletter: [false],
	});
	/**
	 * The url for the map.
	 */
	public mapUrl: SafeResourceUrl;
	/**
	 * Whether the message succeeded.
	 */
	public messageSucceeded = false;


	constructor(
		private fb: FormBuilder,
		private service: ContactService,
		private sanitizer: DomSanitizer,
		private layout: LayoutService,
	) {
		const query = encodeURIComponent('c-suite manufacturing council bountiful ut');
		const url = `https://www.google.com/maps/embed/v1/place?q=${query}&key=${environment.googleApiKey}`;
		this.mapUrl = sanitizer.bypassSecurityTrustResourceUrl(url);
	}


	// ------ ACTIONS ------
	/**
	 * Handle the user clicking the "Send" button.
	 */
	send(): void {
		// Get the form value (include disabled)
		const formValue = this.form.value;

		// Tokenize the stripe card
		this.buttonLoading = true;
		const message = {
			name: formValue.name,
			email: formValue.email,
			company: formValue.company,
			message: formValue.message,
			newsletter: formValue.newsletter,
		};
		this.service.sendMessage(message).pipe(
			finalize(() => this.buttonLoading = false)
		).subscribe(() => {
			this.messageSucceeded = true;
		}, (error: HttpErrorResponse) => {
			const alertMessage = `An error occurred while processing your request.`;
			switch (error.status) {
				default:
					console.error(error);
					break;
			}
			this.layout.addAlert({ content: alertMessage, type: 'error'});
		});
	}

}

<div fxLayout='column' fxLayout.gt-md='row' fxLayoutAlign='center center' fxLayoutAlign.gt-md='center start'>
	<mc-card *ngIf='!messageSucceeded;else thanks'>
		<mc-card-header>
			<h3>Contact Us</h3>
		</mc-card-header>
		<mc-card-body>
			<form [formGroup]='form'>
				<mc-form-field>
					<label>Name</label>
					<input type='text' formControlName='name' />
					<mc-form-field-errors [control]='form.controls.name'>
						<mc-form-field-error rule='required'>This field is required.</mc-form-field-error>
					</mc-form-field-errors>
				</mc-form-field>
				<mc-form-field>
					<label>Email</label>
					<input type='email' formControlName='email' />
					<mc-form-field-errors [control]='form.controls.email'>
						<mc-form-field-error rule='required'>This field is required.</mc-form-field-error>
						<mc-form-field-error rule='email'>This is an invalid email address.</mc-form-field-error>
					</mc-form-field-errors>
				</mc-form-field>
				<mc-form-field>
					<label>Company (optional)</label>
					<input type='text' formControlName='company' />
				</mc-form-field>
				<mc-form-field>
					<label>Message</label>
					<textarea formControlName='message'></textarea>
					<mc-form-field-errors [control]='form.controls.message'>
						<mc-form-field-error rule='required'>This field is required.</mc-form-field-error>
					</mc-form-field-errors>
				</mc-form-field>
				<mc-form-field>
					<mc-checkbox formControlName='newsletter'>Opt-in to our email communication to receive information about our events, insight, news and platform updates.</mc-checkbox>
				</mc-form-field>
			</form>
		</mc-card-body>
		<mc-card-footer>
			<button type='submit' [disabled]='!form.valid || buttonLoading' [class.loading]='buttonLoading' (click)='send()'>Send</button>
		</mc-card-footer>
	</mc-card>
	<div style='margin-top:2em;' [ngStyle.gt-md]="{'margin-top.em':0, 'margin-left.em': 2}">
		<div class='map'>
			<iframe
				width="600"
				height="450"
				frameborder="0"
				style="border:0"
				[src]="mapUrl"
				allowfullscreen
			></iframe>
		</div>
		<address>
			<div>
					C-Suite Network Manufacturing Council<br />
					515 West 500 South<br />
					Bountiful, UT 84010
			</div>
			<div>
				<a href='mailto:info@csuitemfgcouncil.com'>info@csuitemfgcouncil.com</a>
			</div>
		</address>
	</div>
</div>

<ng-template #thanks>
	<mc-card>
		<mc-card-header>
			<h3>Thank you for your message!</h3>
		</mc-card-header>
		<mc-card-body>
			<p>
				We will respond to you soon.
			</p>
		</mc-card-body>
		<mc-card-footer>
			<a class='btn' routerLink='/home'>Return to the home page</a>
		</mc-card-footer>
	</mc-card>
</ng-template>

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { MemberRegistrationComponent } from './registration/components/member-registration.component';
import { SponsorRegistrationComponent } from './registration/components/sponsor-registration.component';
import { SponsorshipComponent } from './sponsorship/sponsorship.component';
import { ContactComponent } from './contact/contact.component';
import { InfoComponent } from './info/info.component';
import { EventComponent } from './events/events.component';
import { ResourceDownloadComponent } from './resources/components/resource-download.component';
import { ResourceListComponent } from './resources/components/resource-list.component';

const routes: Routes = [
	{ path: 'home', component: HomeComponent },
	{ path: 'member-registration', component: MemberRegistrationComponent },
	{ path: 'sponsor-registration', component: SponsorRegistrationComponent },
	{ path: 'sponsorship', component: SponsorshipComponent },
	{ path: 'press', component: InfoComponent },
	{ path: 'contact', component: ContactComponent },
	{ path: 'events', component: EventComponent },

	{ path: 'resources', component: ResourceListComponent },
	{ path: 'resources/download/:id', component: ResourceDownloadComponent },
];

/**
 * the router for the public module
 */
@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class PublicRoutingModule { }

import { Component, Injector, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Resource } from '../interfaces';
import { ResourceService } from '../resource.service';
import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';

import { ResourceRequestComponent, RESOURCE_TOKEN, OVERLAY_REF } from './resource-request.component';

/**
 * The component for the resource list page.
 */
@Component({
	templateUrl: './resource-list.component.html',
	styleUrls: ['./resource-list.component.scss']
})
export class ResourceListComponent implements OnInit {
	/**
	 * The observable of resources.
	 */
	public resources$: Observable<Resource[]>;

	/**
	 * The constructor.
	 *
	 * @param service The resource service.
	 * @param overlay The overlay service.
	 * @param injector The injector.
	 */
	constructor(private service: ResourceService, private overlay: Overlay, private injector: Injector) { }

	/**
	 * Initialize the component.
	 */
	ngOnInit() {
		this.resources$ = this.service.getAll();
	}

	/**
	 * Trigger the request dialog.
	 *
	 * @param resource The resource to request.
	 */
	request(resource: Resource) {
		// Create the overlay
		const overlayRef = this.overlay.create({
			positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
			scrollStrategy: this.overlay.scrollStrategies.block(),
			hasBackdrop: true,
		});

		// Create the injector
		const tokens = new WeakMap<any, any>([
			[RESOURCE_TOKEN, resource],
			[OVERLAY_REF, overlayRef],
		]);
		const injector = new PortalInjector(this.injector, tokens);

		// Attache the component to the overlay
		const portal = new ComponentPortal(ResourceRequestComponent, null, injector);
		overlayRef.attach(portal);

		// Listen for clicks outside overlay
		const clickSub = overlayRef.backdropClick().subscribe(() => {
			overlayRef.dispose();
			clickSub.unsubscribe();
		});
	}
}

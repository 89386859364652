/* eslint-disable  */

export interface Alert {
	content: string;
	type?: AlertType;
	location?: AlertLocation;
	dismissable?: boolean;
	timeout?: number;
}
export type AlertType = 'info' | 'success' | 'warning' | 'error';
export type AlertLocation = 'floating' | 'top';
export interface AlertOptions {
	dismissable?: boolean;
	timeout?: number;
	location?: AlertLocation;
}
export const iconMap = {
	info: 'info-circle',
	warning: 'exclamation-triangle',
	error: 'ban',
	success: 'check-circle',
};

<section [ngStyle.gt-sm]='{"width":"70%"}'>
	<h3>Resources</h3>

	<div class='resource-grid'
		ngStyle.gt-sm='grid-template-columns:1fr 1fr'
		ngStyle.gt-md='grid-template-columns:1fr 1fr 1fr'
		*ngIf='(resources$ | async) as resources'>
		<div class='resource-card' *ngFor='let resource of resources'>
			<div class='type'>{{ resource.Type }}</div>
			<div class='title'>{{ resource.Title }}</div>
			<div class='author'>{{ resource.Author }}</div>
			<div class='source'>
				<a [href]='resource.source.URL' target='_blank'>
					{{ resource.source.Name }}
				</a>
			</div>
			<div style='flex:auto'></div>
			<div *ngIf='!resource.Link' class='link' (click)='request(resource)'>
				Request
			</div>
			<a *ngIf='resource.Link && (resource.Type === "RADIO" || resource.Type === "PODCAST")' class='link' target='_blank' [href]='resource.Link' >
				Listen
			</a>
			<a *ngIf='resource.Link && !(resource.Type === "RADIO" || resource.Type === "PODCAST")' class='link' target='_blank' [href]='resource.Link' >
				View
			</a>
		</div>
	</div>
</section>

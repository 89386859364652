import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ResourceService } from '../resource.service';
import { flatMap, tap } from 'rxjs/operators';
import { Resource } from '../interfaces';

/**
 * The component for the resource download page.
 */
@Component({
	templateUrl: './resource-download.component.html',
	styleUrls: ['./resource-download.component.scss']
})
export class ResourceDownloadComponent implements OnInit {
	/**
	 * The resource to download.
	 */
	public resource: Resource;
	/**
	 * The time remaining.
	 */
	public remaining = 20;

	/**
	 * The constructor.
	 *
	 * @param route The current route.
	 * @param service The resource service.
	 * @param router The router.
	 */
	constructor(private route: ActivatedRoute, private service: ResourceService, private router: Router) { }

	/**
	 * Initialize the component.
	 */
	ngOnInit() {
		this.countdown();
		const id = parseInt(this.route.snapshot.paramMap.get('id'), 10);
		this.service.get(id).pipe(
			tap(resource => this.resource = resource),
			flatMap(resource => this.service.download(resource.ResourceID)),
		).subscribe();
	}

	/**
	 * Run the page countdown.
	 */
	private countdown(): void {
		if (this.remaining > 0) {
			setTimeout(function() {
				this.remaining--;
				this.countdown();
			}.bind(this), 1000);
		} else {
			this.router.navigateByUrl('/resources');
		}
	}
}

<div class='close-button' (click)='close()'></div>

<h4>Request a resource</h4>
<div class='title'>{{ resource.Title }}</div>

<form [formGroup]='form' (ngSubmit)='sendRequest()'>
	<mc-form-field>
		<label>Name</label>
		<input type='text' formControlName='name' />
		<mc-form-field-errors [control]='form.controls.name'>
			<mc-form-field-error rule='required'>This field is required.</mc-form-field-error>
		</mc-form-field-errors>
	</mc-form-field>
	<mc-form-field>
		<label>Email</label>
		<input type='email' formControlName='email' />
		<mc-form-field-errors [control]='form.controls.email'>
			<mc-form-field-error rule='required'>This field is required.</mc-form-field-error>
			<mc-form-field-error rule='email'>Not a valid email.</mc-form-field-error>
		</mc-form-field-errors>
	</mc-form-field>
	<mc-form-field>
		<label>Company (optional)</label>
		<input type='text' formControlName='company' />
	</mc-form-field>
	<mc-form-field>
		<mc-checkbox formControlName='newsletter'>Opt-in to our email communication to receive information about our events, insight, news and platform updates.</mc-checkbox>
	</mc-form-field>

	<div class='recaptcha'>
		This site is protected by reCAPTCHA and the Google
		<a href="https://policies.google.com/privacy" target='_blank'>Privacy Policy</a> and
		<a href="https://policies.google.com/terms" target='_blank'>Terms of Service</a> apply.
	</div>

	<div style='text-align:center'>
		<button type='submit' [disabled]='!form.valid || buttonLoading' [class.loading]='buttonLoading'>Request</button>
	</div>
</form>

import { AfterViewInit, Component, ElementRef } from '@angular/core';

let nextId = 1;


/**
 * A form field wraps all tags related to a single field (label, input, select, errors, etc).
 */
@Component({
	selector: 'mc-form-field',
	templateUrl: './form-field.component.html',
	styleUrls: ['./form-field.component.scss'],
})
export class FormFieldComponent implements AfterViewInit {
	/**
	 * The ID of the form field component (increments to ensure uniqueness).
	 */
	public id = nextId++;

	/**
	 * The constructor.
	 *
	 * @param el The reference to the DOM elements.
	 */
	constructor(private el: ElementRef) { }

	/**
	 * Adds `id` attributes to input fields and `for` attributes to labels.
	 */
	ngAfterViewInit(): void {
		const label = this.el.nativeElement.querySelector('label');
		const field = this.el.nativeElement.querySelector('input, select');
		if (label && field) {
			const id = `mc-form-field-${this.id}`;
			label.setAttribute('for', id);
			field.id = id;
		}
	}
}

import { OverlayContainer } from '@angular/cdk/overlay';
import { Component, HostBinding } from '@angular/core';

import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';
import { Angulartics2Facebook } from 'angulartics2/facebook';

import { environment } from '../environments/environment';

const defaultTheme = 'theme-light';

declare global {
	interface Window {
		/**
		 * The event brite widgets.
		 */
		EBWidgets: any;
	}
}

/**
 * The application component.
 */
@Component({
	selector: 'mc-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent {
	/**
	 * Bind the theme to the app class.
	 */
	@HostBinding('class') theme = defaultTheme;
	/**
	 * To show the menu on small screens.
	 */
	public showMenu = false;
	/**
	 * To show the info menu.
	 */
	public showInfoMenu = false;

	/**
	 * The constructor.
	 *
	 * @param overlayContainer The overlay container.
	 */
	constructor(
		googleAnalytics: Angulartics2GoogleAnalytics,
		facebookPixel: Angulartics2Facebook,
		overlayContainer: OverlayContainer,
	) {
		// Add the class to the overlay container too
		overlayContainer.getContainerElement().classList.add(defaultTheme);

		// Enable google analytics tracking
		if (environment.googleAnalytics) {
			(window as any).ga('create', environment.googleAnalytics, 'auto');
			googleAnalytics.startTracking();
		}

		// Enable facebook pixel
		if (environment.facebookPixel) {
			(window as any).fbq('init', '284256342489495');
			(window as any).fbq('track', 'PageView');
			facebookPixel.startTracking();
		}
	}

	/**
	 * Returns the current year for copyright purposes.
	 *
	 * @return The current year.
	 */
	get year(): number {
		return (new Date()).getFullYear();
	}
}

import { Component } from '@angular/core';

/**
 * The component for the information page.
 */
@Component({
	selector: 'mc-info',
	templateUrl: './info.component.html',
	styleUrls: ['./info.component.scss']
})
export class InfoComponent {
	constructor() { }
}

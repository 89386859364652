<div class="banner">
	<div>
		<div class="banner-logo-wrapper">
			<img src="/assets/images/manufacturing-council-logo-WHT-LG.png"/>
		</div>
	</div>
</div>
<div class="text-body">
	<div id="AboutUs">
		<h3 class="section-title"><strong>About Us</strong></h3>
		<p>
			The C-Suite Manufacturing Council is dedicated to helping improve manufacturing in the United
				States through thought leadership and working groups dedicated to producing solutions to the
			problems manufacturers face. Such as:
		</p>
		<ul class="info-list">
			<li>
				<strong>Innovation</strong> &mdash; <span>Why is the US being out-innovated in the world marketplace
					and what do we do about it?</span>
			</li>
			<li>
				<strong>Workforce</strong> &mdash; <span>How do we solve the workforce deficit and change the perception
					of US manufacturing?</span>
			</li>
			<li>
				<strong>Policy</strong> &mdash; <span>How do we adapt to changing tariffs and government policies?</span>
			</li>
			<li>
				<strong>Supply chain</strong> &mdash; <span>How do we shorten the supply chain and connect with
				manufacturers closer to our facilities and why can't we find them today?</span>
			</li>
			<li>
				<strong>Process taxonomy</strong> &mdash; <span>Why is there no standard definition of manufacturing
				processes and how do we build and maintain one?</span>
			</li>
		</ul>
	</div>
	<div id="Benefits">
		<h3 class="section-title"><strong>Benefits</strong></h3>
		<p>
			Members of the C-Suite Manufacturing Council receive:
		</p>
		<ul class="info-list">
			<li>
				<span>access to content created by the Council</span>
			</li>
			<li>
				<span>invitations to council events and working groups, including two in-person meetings and
					two digital meetings</span>
			</li>
			<li>
				<span>networking opportunities</span>
			</li>
			<li>
				<span>combining with other great minds in the industry to derive practical solutions</span>
			</li>
			<li>
				<span>opportunities to help produce thought leadership content</span>
			</li>
		</ul>
		<p>
			Members may also opt for additional C-Suite benefits by becoming an influencer, leader, or
			participant in Council decision making.
		</p>
	</div>
	<div id="Events">
		<h3 class="section-title"><strong>Events</strong></h3>
		<p>
			The C-Suite Manufacturing Council holds two digital and two in-person member meetings each year.
			The two in-person meetings are held in conjunction with C-Suite Network Conferences in June and
			December.
		</p>
		<p>
			In 2019 there is one additional meeting being held in Las Vegas on March 21st.
			Those opting for the &lsquo;Leader&rsquo; member are also invited to attend the C-Suite Hero
			Club event being held in Las Vegas March 18th-20th.
		</p>
	</div>
	<div id="Membership">
		<h3 class="section-title"><strong>Membership</strong></h3>
		<p>
			The C-Suite Manufacturing Council is looking for leaders in manufacturing dedicated to making
			manufacturing thrive. Thought leaders and influencers help make a positive impact on their
			companies, their industry, their country, and every life made better by the efforts of great
			manufacturers. Every individual can help, many can bring about change, and we can help you do it!
		</p>
	</div>
</div>
<div style="margin-top: 35px;">
	<mc-benefits-table style="display:block;"
		[title]="table.title"
		[link]="table.formLink"
		[headers]="table.headers"
		[data]="table.data">
	</mc-benefits-table>
</div>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule, FaIconLibrary, FaConfig } from '@fortawesome/angular-fontawesome';
import { faBan, faCheckCircle, faExclamationTriangle, faSpinner, faTimes, faInfoCircle } from '@fortawesome/free-solid-svg-icons';

import { CheckboxComponent, ErrorGroupComponent, ErrorMessageComponent, FormFieldComponent, SelectComponent } from './forms';
import { CardComponent, CardBodyComponent, CardFooterComponent, CardHeaderComponent } from './card';
import { FloatingAlertComponent, InlineAlertComponent } from './alerts';

/**
 * The layout module houses components that are used throughout the application to layout pages.
 */
@NgModule({
	declarations: [
		CardComponent,
		CardBodyComponent,
		CardHeaderComponent,
		CardFooterComponent,
		CheckboxComponent,
		ErrorGroupComponent,
		ErrorMessageComponent,
		FloatingAlertComponent,
		FormFieldComponent,
		InlineAlertComponent,
		SelectComponent,
	],
	exports: [
		CardComponent,
		CardBodyComponent,
		CardFooterComponent,
		CardHeaderComponent,
		CheckboxComponent,
		ErrorGroupComponent,
		ErrorMessageComponent,
		FloatingAlertComponent,
		FormFieldComponent,
		InlineAlertComponent,
		SelectComponent,
	],
	imports: [
		CommonModule,
		FontAwesomeModule,
		ReactiveFormsModule,
	]
})
export class LayoutModule {
	constructor(private library: FaIconLibrary, private faConfig: FaConfig) {
		library.addIcons(faBan, faCheckCircle, faExclamationTriangle, faInfoCircle, faSpinner, faTimes);
	}
}

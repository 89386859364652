import { Environment } from './interface';

export const environment: Environment = {
	production: true,
	stripePublishableKey: 'pk_live_7POeZZVn4b55Dx2ZONpiWYvb',
	api: 'https://api.csuitemfgcouncil.com',
	googleAnalytics: 'UA-136099577-1',
	facebookPixel: '284256342489495',
	recaptcha: '6LdiC6sUAAAAAABRb-s_is5Wg_fa_JsMTjbca1Xp',
	googleApiKey: 'AIzaSyCLvyiC3qD7GZqoGuAZkNJVT5KVoLePGIQ',
};

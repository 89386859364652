import { Component } from '@angular/core';
import { Observable } from 'rxjs';

import { LayoutService } from '../layout.service';

import { Alert, iconMap } from '../interfaces';


/**
 * Displays the floating alerts.
 */
@Component({
	selector: 'mc-floating-alert',
	templateUrl: './floating-alert.component.html',
	styleUrls: ['./floating-alert.component.scss'],
})
export class FloatingAlertComponent {
	/**
	 * The alerts to show.
	 */
	public alerts: Observable<Alert[]>;

	/**
	 * The constructor.
	 *
	 * @param layout The layout service.
	 */
	constructor(private layout: LayoutService) {
		this.alerts = this.layout.floatingAlerts;
	}

	/**
	 * Remove an alert.
	 *
	 * @param alert The alert to remove.
	 */
	removeAlert(alert: Alert): void {
		this.layout.removeAlert(alert);
	}

	/**
	 * Gets the icon for a given alert.
	 *
	 * @param alert The alert.
	 * @return The icon name.
	 */
	getIcon(alert: Alert): string {
		return iconMap[alert.type] || null;
	}
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpCollectionState, HttpRecordState } from '@i5/ngx-common';
import { Observable } from 'rxjs';
import { flatMap, map, tap } from 'rxjs/operators';
import * as FileSaver from 'file-saver';

import { Resource, ResourceRequestInfo } from './interfaces';
import { environment } from 'src/environments/environment';
import { ReCaptchaV3Service } from 'ng-recaptcha';

/**
 * Provides methods to interface with backend resoruces.
 */
@Injectable()
export class ResourceService {
	/**
	 * The resource url.
	 */
	private resourceUrl = `${environment.api}/mfg-council/resources`;

	/**
	 * The constructor.
	 *
	 * @param http The http client.
	 * @param recaptcha The recaptcha service.
	 */
	constructor(private http: HttpClient, private recaptcha: ReCaptchaV3Service) { }

	/**
	 * Get all the resources available.
	 *
	 * @param state The collection state.
	 */
	getAll(state: Partial<HttpCollectionState> = {}): Observable<Resource[]> {
		const params = {
			page: '1',
			'per-page': '100',
		};
		return this.http.get<Resource[]>(this.resourceUrl, { params });
	}

	/**
	 * Get a single resource.
	 *
	 * @param id The resource ID.
	 * @param state The request state.
	 */
	get(id: number, state: Partial<HttpRecordState> = {}): Observable<Resource> {
		return this.http.get<Resource>(`${this.resourceUrl}/${id}`);
	}

	/**
	 * Request a resource.
	 *
	 * @param id The resource ID.
	 * @param info The requestors info.
	 */
	request(id: number, info: ResourceRequestInfo): Observable<null> {
		return this.recaptcha.execute('requestResource').pipe(
			flatMap(token => this.http.post<null>(`${this.resourceUrl}/${id}/request`, { ...info, recaptcha: token }))
		);
	}

	/**
	 * Download a given resource.
	 *
	 * @param id The resource ID.
	 */
	download(id: number): Observable<null> {
		return this.recaptcha.execute('downloadResource').pipe(
			flatMap(token => this.http.get(
					`${this.resourceUrl}/${id}/download`,
					{ responseType: 'blob', observe: 'response', params: { recaptcha: token } }
				)),
			tap(response => {
				const disposition = response.headers.get('content-disposition');
				const mimeType = response.headers.get('content-type');
				const filename = disposition.match(/filename=\"(.*?)\";/)[1];
				const download = disposition.match(/^[a-z]+/)[0] === 'attachment';
				if (download) {
					FileSaver.saveAs(response.body, filename);
				} else {
					const blob = new Blob([response.body]);
					const file = new File([response.body], filename, { type: mimeType });
					const url = URL.createObjectURL(file);
					window.open(url, '_blank');
				}
			}),
			map(() => null),
		);
	}
}

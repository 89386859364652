import { Component, Input, HostBinding } from '@angular/core';

import { AlertType, iconMap } from '../interfaces';

/**
 * Represents an inline alert.
 */
@Component({
	selector: 'mc-alert',
	templateUrl: './inline-alert.component.html',
	styleUrls: ['./inline-alert.component.scss'],
})
export class InlineAlertComponent {
	/**
	 * The type of alert.
	 */
	@Input() type: AlertType;
	/**
	 * Set the class to the type of the alert.
	 */
	@HostBinding('class') get class() { return this.type; }

	/**
	 * Get the icon to use for the alert.
	 *
	 * @return The icon type.
	 */
	get icon(): string|null {
		return iconMap[this.type] || null;
	}
}

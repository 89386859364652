import { Component, Inject, InjectionToken } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { OverlayRef } from '@angular/cdk/overlay';
import { finalize, flatMap } from 'rxjs/operators';
import { Resource, ResourceRequestInfo } from '../interfaces';
import { ResourceService } from '../resource.service';
import { LayoutService } from 'src/app/layout';
import { Alert } from 'src/app/layout/interfaces';

export const OVERLAY_REF = new InjectionToken<unknown>('OVERLAY_REF');
export const RESOURCE_TOKEN = new InjectionToken<unknown>('RESOURCE_TOKEN');

/**
 * The component for the resource request page.
 */
@Component({
	templateUrl: './resource-request.component.html',
	styleUrls: ['./resource-request.component.scss']
})
export class ResourceRequestComponent {
	/**
	 * The info form.
	 */
	public form: FormGroup = this.fb.group({
		name: ['', Validators.required],
		email: ['', [Validators.required, Validators.email]],
		company: [''],
		newsletter: [false],
	});
	/**
	 * Whether to show the loading icon in the button.
	 */
	public buttonLoading = false;

	/**
	 * The constructor.
	 *
	 * @param resource The resource to request.
	 * @param overlayRef The overlay ref.
	 * @param fb The form builder.
	 * @param service The resource service.
	 * @param layout The layout service.
	 */
	constructor(
		@Inject(RESOURCE_TOKEN) public resource: Resource,
		@Inject(OVERLAY_REF) public overlayRef: OverlayRef,
		private fb: FormBuilder,
		private service: ResourceService,
		private layout: LayoutService,
	) { }

	/**
	 * Close the dialog.
	 */
	close(): void {
		this.overlayRef.dispose();
	}

	/**
	 * Send the resource request.
	 */
	sendRequest(): void {
		if (!this.form.valid) {
			console.error('Form is not valid');
			return;
		}

		this.buttonLoading = true;
		const info: ResourceRequestInfo = {
			name: this.form.value.name,
			email: this.form.value.email,
			company: this.form.value.company,
			newsletter: this.form.value.newsletter,
		};
		this.service.request(this.resource.ResourceID, info).pipe(
			finalize(() => this.buttonLoading = false),
		).subscribe(
			() => {
				const alert: Alert = {
					type: 'success',
					content: `We've received your request. Please check your email.`,
				};
				this.layout.addAlert(alert);
				this.close();
			}, (error) => {
				console.error(error);
				const email = 'info@csuitemfgcouncil.com';
				const alert: Alert = {
					type: 'error',
					content: `Something went wrong. Please try again or contact us at ${email}.`,
				};
				this.layout.addAlert(alert);
			}
		);
	}
}

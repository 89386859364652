import { Environment } from './interface';

export const environment: Environment = {
	production: false,
	stripePublishableKey: 'pk_test_cPIqMEUKkb25VTJLiHjOjHXe',
	api: 'https://testapi.csuitemfgcouncil.com',
	googleAnalytics: false,
	facebookPixel: false,
	recaptcha: '6LfGSawUAAAAAKRozeyshR1LqrvEKugvDxQjgRvd',
	googleApiKey: 'AIzaSyCLvyiC3qD7GZqoGuAZkNJVT5KVoLePGIQ',
};

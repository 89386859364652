import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import {
	StripeElementClasses,
	StripeElementComponent,
	StripeElementError,
	StripeElementFont,
	StripeElementStyle,
	StripeToken,
	StripeValidators,
} from '@i5/ngx-stripe';
import { finalize, flatMap } from 'rxjs/operators';

import { SponsorRegistrationData, SponsorshipLevel, RegistrationLineItem } from '../interfaces';
import { RegistrationService } from '../registration.service';
import { LayoutService } from '../../../layout';


/**
 * The registration component.
 */
@Component({
	templateUrl: './sponsor-registration.component.html',
	styleUrls: ['./sponsor-registration.component.scss']
})
export class SponsorRegistrationComponent implements OnInit {
	/**
	 * References the stripe component in the template
	 */
	@ViewChild('stripeComponent') stripeComponent: StripeElementComponent;
	/**
	 * Whether to show the button as loading.
	 */
	public buttonLoading = false;
	/**
	 * The employee ranges available to select
	 */
	public employees: string[] = ['5 or less', '5 - 10', '10 - 25', '25 - 100', '100 - 500', '500+'];
	/**
	 * The form definition
	 */
	public form: FormGroup = this.fb.group({
		name: ['', Validators.required],
		email: ['', [Validators.required, Validators.email]],
		phone: ['', Validators.required],
		company: ['', Validators.required],
		title: ['', Validators.required],
		revenue: ['', Validators.required],
		employees: ['', Validators.required],
		cardInfo: ['', [StripeValidators.required, StripeValidators.valid]],
		sponsorshipLevel: ['sponsorship-leadership', Validators.required],
		newsletter: [false],
	});
	/**
	 * The sponsorship levels available
	 */
	public levels: SponsorshipLevel[] = [
		{ id: 'sponsorship-leadership', name: 'Leadership', amount: 50000 },
		{ id: 'sponsorship-benefactor', name: 'Benefactor', amount: 25000 },
		{ id: 'sponsorship-partner', name: 'Partner', amount: 12500 },
		{ id: 'sponsorship-contributor', name: 'Contributor', amount: 2500 },
	];
	/**
	 * Whether or not a payment has succeeded
	 */
	public paymentSucceeded = false;
	/**
	 * The mask for the phone number input. See https://github.com/text-mask/text-mask/blob/master/componentDocumentation.md#readme
	 */
	public phoneMask = {
		mask: ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
		guide: false,
	};
	/**
	 * The list of revenue ranges the user can select
	 */
	public revenues: string[] = ['Less than $1M', '$1M - $2M', '$2M - $5M', '$5M - $10M', '$10M - $25M', '$25M+'];
	/**
	 * The fonts to load into the stripe component
	 */
	public stripeFonts: StripeElementFont[] = [ { cssSrc: 'https://fonts.googleapis.com/css?family=Montserrat' } ];
	/**
	 * The styles to load into the stripe component
	 */
	public stripeStyle: StripeElementStyle = { base: { fontFamily: 'Montserrat' } };
	/**
	 * The classes to use to configure the stripe component
	 */
	public stripeClasses: StripeElementClasses = { focus: 'focused' };
	/**
	 * The list of job titles a user can select from
	 */
	public titles: string[] = ['VP', 'Founder', 'Co-Founder', 'Investor', 'President', 'CMO', 'COO', 'CEO', 'CFO'];


	// ------- COMPONENT CONFIG ------
	/**
	 * The constructor.
	 *
	 * @param fb The form builder.
	 * @param service The registration service.
	 * @param layout The layout service.
	 */
	constructor(
		private fb: FormBuilder,
		private service: RegistrationService,
		private layout: LayoutService,
		private route: ActivatedRoute
	) { }

	/**
	 * Initialize the component
	 */
	ngOnInit(): void {
		// Set the sponsorship level from the parameter, if it exists
		const defaultLevel = this.route.snapshot.queryParams.level || 'sponsorship-leadership';
		this.form.controls.sponsorshipLevel.setValue(defaultLevel);
	}


	// ------ ACTIONS ------
	/**
	 * Handle the user clicking the "Join" button.
	 */
	sponsor(): void {
		// Get the form value (include disabled)
		const formValue = this.form.getRawValue();

		// Unmask the phone value
		formValue.phone = formValue.phone.replace(/\D+/g, '');

		// Tokenize the stripe card
		this.buttonLoading = true;
		this.stripeComponent.tokenize().pipe(
			flatMap((token: StripeToken) => {
				// Send the registration data to the backend
				const joinRequest: SponsorRegistrationData = {
					name: formValue.name,
					email: formValue.email,
					phone: formValue.phone,
					company: formValue.company,
					title: formValue.title,
					revenue: formValue.revenue,
					employees: formValue.employees,
					sponsorshipLevel: formValue.sponsorshipLevel,
					newsletter: formValue.newsletter,
					stripeToken: token.id,
				};
				return this.service.registerSponsor(joinRequest);
			}),
			finalize(() => this.buttonLoading = false)
		).subscribe(() => {
			this.paymentSucceeded = true;
		}, (error: StripeElementError|HttpErrorResponse) => {
			if (error instanceof HttpErrorResponse) {
				let message = `An error occurred while processing your request.`;
				switch (error.status) {
					case 400:
						const messages = {
							1: error.error.message, // pass on stripe error
							2: 'It looks like you may already be a sponsor of the council. Please '
								+ 'contact us a info@csuitemfgcouncil.com for more information.',
						};
						message = messages.hasOwnProperty(error.error.code) ? messages[error.error.code] : message;
						break;
					case 422:
						message = 'There was a form validation error:';
						for (const field in error.error) {
							if (error.error.hasOwnProperty(field)) {
								message += '<br>' + error.error[field].join('<br>');
							}
						}
						break;
					default:
						console.error(error);
						break;
				}
				this.layout.addAlert({ content: message, type: 'error'});
			} else {
				const message = error.hasOwnProperty('message') ? error.message : JSON.stringify(error);
				this.layout.addAlert({ content: message, type: 'error'});
			}
		});
	}


	// ------ GETTERS ------
	/**
	 * Get the line items to show for the registration summary.
	 *
	 * @return The payment line items.
	 */
	get lineItems(): RegistrationLineItem[] {
		const items: RegistrationLineItem[] = [
			{ description: this.sponsorshipLevel.name, amount: this.sponsorshipLevel.amount }
		];

		return items;
	}

	/**
	 * Get the current sponsorship level.
	 *
	 * @return The currently selected sponsorship level.
	 */
	get sponsorshipLevel(): SponsorshipLevel {
		return this.levels.find(level => level.id === this.form.value.sponsorshipLevel);
	}

	/**
	 * Get the total amount (sum of line items).
	 *
	 * @return The total registration amount.
	 */
	get total(): number {
		return this.lineItems.reduce((carry, item) => carry + item.amount, 0);
	}
}

import { Injectable } from '@angular/core';
import { Message } from './interfaces/message';
import { environment } from 'src/environments/environment';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { flatMap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

/**
 * Contains methods for handling basic messaging.
 */
@Injectable({
	providedIn: 'root',
})
export class ContactService {
	/**
	 * The resource url.
	 */
	private contactUrl = `${environment.api}/mfg-council/contact`;

	/**
	 * The constructor.
	 *
	 * @param http The http client.
	 * @param recaptcha The recaptcha service.
	 */
	constructor(private http: HttpClient, private recaptcha: ReCaptchaV3Service) { }


	/**
	 * Sends a message.
	 *
	 * @param message The message to send.
	 */
	public sendMessage(message: Message): Observable<null> {
		return this.recaptcha.execute('sendMessage').pipe(
			flatMap(token => this.http.post<null>(`${this.contactUrl}/send-message`, { ...message, recaptcha: token }))
		);
	}
}

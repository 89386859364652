import { Component, Optional } from '@angular/core';

import { CardComponent } from './card.component';

/**
 * Represents the header of a styled card element.
 */
@Component({
	selector: 'mc-card-header',
	template: '<ng-content></ng-content>',
	styleUrls: ['./card-header.component.scss'],
})
export class CardHeaderComponent {
	/**
	 * The constructor.
	 *
	 * @param card The card this component is in.
	 */
	constructor(@Optional() private card: CardComponent) {
		if (!this.card) {
			throw new Error('mc-card-header must be a child of mc-card');
		}
	}
}

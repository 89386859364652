<div class="banner">
	<div>
		<div class="banner-logo-wrapper">
			<img src="/assets/images/manufacturing-council-logo-BLK-SM.png"/>
		</div>
	</div>
</div>
<div class="text-body">
	<p>
		In the C-Suite Manufacturing Council we work together to find ways to improvemanufacturing.
		Rather than just talk about problems, we identify solutions and carry out corrective actions.
		To do this, we gather the best and brightest minds and those who want to make a difference.
		We meet regularly and produce thought leading content and industry changing solutions.
	</p>
	<p><strong>Interested in being a sponsor? There are many benefits, depending on your sponsorship level:</strong></p>
	<ul class="info-list">
		<li>
			<strong>Contributor</strong> &mdash; <span>Have your brand included on our marketing materials,
			website and emails to members, have on-site signage at our events, table/booth space at events,
			receive three complimentary memberships to the council, and an option to participate in advisory meetings.</span>
		</li>
		<li>
			<strong>Partner</strong> &mdash; <span>In addition to the Contributor benefits, participate on the
			council advisory board, have a feature article and press release on the website, and extend your
			reach into the C-Suite Network with two C-Suite Black Memberships and an expert profile listing on
			the C-Suite Network Advisor website.</span>
		</li>
		<li>
			<strong>Benefactor</strong> &mdash; <span> In addition to the Partner benefits, be listed as a
			sponsor and have a booth at C-Suite Network Conferences, receive three C-Suite Black Memberships,
			and have a C-Suite Network sponsorship press release.</span>
		</li>
		<li>
			<strong>Leadership Circle</strong> &mdash; <span>In addition to Benefactor benefits, be a guest
			on C-Suite TV Insights, receive five C-Suite Black Memberships, and be considered as an event
			speaker, a panel guest and as a member of the Hero Club. </span>
		</li>
	</ul>
</div>
<div style="margin-top: 35px;">
	<mc-benefits-table style="display:block;" [link]="table.formLink" [title]="table.title" [headers]="table.headers" [data]="table.data"></mc-benefits-table>
</div>

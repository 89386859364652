import { Component } from '@angular/core';

/**
 * The sponsorship component.
 */
@Component({
	templateUrl: './sponsorship.component.html',
	styleUrls: ['./sponsorship.component.scss']
})
export class SponsorshipComponent {
	/**
	 * configuration for sponsorship benefit table
	 */
	table = {
		title: 'Benefits',
		formLink: '/sponsor-registration',
		headers: [
			{
				name: 'Contributor',
				description: 'Annual Subscription',
				price: 2500,
				hidden: true,
				params: {level: 'sponsorship-contributor'},
			},
			{
				name: 'Partner',
				description: 'Annual Subscription',
				price: 12500,
				hidden: true,
				params: {level: 'sponsorship-partner'},
			},
			{
				name: 'Benefactor',
				description: 'Annual Subscription',
				price: 25000,
				hidden: true,
				params: {level: 'sponsorship-benefactor'},
			},
			{
				name: 'Leadership Circle',
				description: 'Annual Subscription',
				price: 50000,
				hidden: true,
				params: {level: 'sponsorship-leadership'},
			},
		],
		data: [
			{
				name: 'Brand included on email blasts',
				benefits: [1, 1, 1, 1]
			},
			{
				name: 'On-site signage at C-Suite Manufacturing Council (CMC) events',
				benefits: [1, 1, 1, 1]
			},
			{
				name: 'Access to content created by the CMC',
				benefits: [1, 1, 1, 1]
			},
			{
				name: 'One flyer per CMC event',
				benefits: [1, 1, 1, 1]
			},
			{
				name: 'Brand on CMC marketing materials',
				benefits: [1, 1, 1, 1]
			},
			{
				name: 'Showcase products/services on website',
				benefits: [1, 1, 1, 1]
			},
			{
				name: 'Press release',
				benefits: [1, 1, 1, 1]
			},
			{
				name: 'Booth at CMC conference',
				benefits: [1, 1, 1, 1]
			},
			{
				name: '** Invitation to C-Suite Manufacturing Council advisory meetings',
				benefits: [1, 1, 1, 1]
			},
			{
				name: '3 CMC member subscriptions',
				benefits: [1, 1, 1, 1]
			},
			{
				name: 'Feature article and Press release on website - Half Page ad included',
				benefits: [0, 1, 1, 1]
			},
			{
				name: 'Promote Co-Branded Products/Services',
				benefits: [0, 1, 1, 1]
			},
			{
				name: 'Seat on C-Suite Manufacturing Council Advisory Board',
				benefits: [0, 1, 1, 1]
			},
			{
				name: 'C-Suite Black Memberships',
				benefits: [0, 1, 1, 1]
			},
			{
				name: 'Expert Profile Listing on C-Suite Network Advisor website ',
				benefits: [0, 1, 1, 1]
			},
			{
				name: 'Listed Sponsor for C-Suite Network Conferences',
				benefits: [0, 0, 1, 1]
			},
			{
				name: 'Booth at C-Suite Network Conference',
				benefits: [0, 0, 1, 1]
			},
			{
				name: 'Sponsorship Announcement Press Release on C-Suite Network',
				benefits: [0, 0, 1, 1]
			},
			{
				name: 'Guest on C-Suite TV Insights',
				benefits: [0, 0, 0, 1]
			},
			{
				name: 'Consideration of	being a Panel Guest at one C-Suite Conference',
				benefits: [0, 0, 0, 1]
			},
			{
				name: 'Consideration of being a Speaker for Events',
				benefits: [0, 0, 0, 1]
			},
			{
				name: 'Hero Club membership (subject to qualification)',
				benefits: [0, 0, 0, 1]
			},
			{
				name: 'Listed Sponsor for Hero Club City Summit',
				benefits: [0, 0, 0, 1]
			},
		]
	};
	constructor() { }
}

<ng-container *ngIf='false; else public'>.
	<!-- put the application layout in this ng-container -->
	<router-outlet></router-outlet>
</ng-container>
<ng-template #public>
	<div *ngIf="showMenu" class="menu-catch" (click)="showMenu = false;"></div>
	<div fxLayout='column' class='wrapper'>
		<header fxLayout='row' fxLayoutAlign='start center'>
			<div class="logo">
				<img routerLink='/home' src="assets/images/manufacturing-council-logo-BLK-SM.png" />
			</div>
			<div fxFlex></div>
			<div class="navbar-wrapper">
				<nav class="links" fxHide fxShow.gt-sm (click)="showInfoMenu = false;">
					<div class="navbar-link" style="position: relative" (click)="showInfoMenu = !showInfoMenu; $event.stopPropagation();">
						<span>Info</span>
						<div class="menu" *ngIf="showInfoMenu" (click)="showInfoMenu = false; $event.stopPropagation();">
							<a href='https://covid-19.manufacturersmarketplace.us/' target='_blank' class="navbar-link">
								<span>COVID-19 Tool</span>
							</a>
							<a routerLink='/events' class="navbar-link">
								<span>Events</span>
							</a>
							<a routerLink='/press' class="navbar-link">
								<span>Press</span>
							</a>
							<a routerLink='/resources' class="navbar-link">
								<span>Resources</span>
							</a>
						</div>
					</div>
					<a routerLink='/sponsorship' class="navbar-link">
						<span>Sponsorships</span>
					</a>
					<a routerLink='/contact' class="navbar-link">
						<span>Contact Us</span>
					</a>
					<a routerLink='/member-registration' [queryParams]="{level: 'membership-influencer'}" class="navbar-link">
						<span>Join</span>
					</a>
				</nav>
				<nav class="mobile-links" fxShow fxHide.gt-sm>
					<div class="navbar-link" style="position: relative" (click)="showMenu = !showMenu">
						<fa-icon [icon]="'bars'" class="mobile-bars"></fa-icon>
						<div class="menu" *ngIf="showMenu" (click)="showMenu = false;">
							<a href='https://covid-19.manufacturersmarketplace.us/' target='_blank' class="navbar-link">
								<span>COVID-19 Tool</span>
							</a>
							<a routerLink='/events' class="navbar-link">
								<span>Events</span>
							</a>
							<a routerLink='/press' class="navbar-link">
								<span>Press</span>
							</a>
							<a routerLink='/resources' class="navbar-link">
								<span>Resources</span>
							</a>
							<a routerLink='/sponsorship' class="navbar-link">
								<span>Sponsorships</span>
							</a>
							<a routerLink='/contact' class="navbar-link">
								<span>Contact Us</span>
							</a>
							<a routerLink='/member-registration' [queryParams]="{level: 'membership-influencer'}" class="navbar-link">
								<span>Join</span>
							</a>
						</div>
					</div>
				</nav>
			</div>
		</header>
		<main fxFlex>
			<router-outlet></router-outlet>
		</main>
		<footer fxLayout='column' fxLayout.gt-sm='row' fxLayoutAlign='start center' fxLayoutGap='2em'>
			<div class="logo">
				<a routerLink='/home'>
					<img src="assets/images/manufacturing-council-logo-WHT-LG.png"/>
				</a>
			</div>
			<div class='address' [ngStyle]="{'text-align': 'center'}" [ngStyle.gt-sm]="{'text-align': 'left'}">
				<p>{{ year }} &copy; Copyright</p>
				<p>C-Suite Manufacturing Council</p>
				<p>515 West 500 South</p>
				<p>Bountiful, UT 84010</p>
			</div>
			<div fxFlex></div>
			<div class="logo end-logo" fxHide fxShow.gt-sm>
				<a href="https://c-suitenetwork.com/">
					<img src="assets/images/c-suite-WHT.png"/>
				</a>
			</div>
		</footer>
	</div>
</ng-template>
<mc-floating-alert></mc-floating-alert>

/* eslint-disable  */

import { Component, Input } from '@angular/core';

/**
 * a component that renders a table of benefits from a configuration object.
 */
@Component({
	selector: 'mc-benefits-table',
	templateUrl: './benefits-table.component.html',
	styleUrls: ['./benefits-table.component.scss']
})
export class BenefitsTableComponent {

	/**
	 * the title for the whole table.
	 */
	@Input() title: string;
	/**
	 * where the buttons link to.
	 */
	@Input() link: string;
	/**
	 * the header for each row.
	 */
	@Input() headers: {
		name: string,
		description: string,
		price: number,
		hidden: boolean,
		params: {[key: string]: string}
	}[];
	/**
	 * the actual table data.
	 */
	@Input() data: {
		name: string,
		benefits: number[]
	}[];

	constructor() { }

	/**
	 * generates benefits list objects for when the screen is below a certain pixel
	 * @param data table data
	 * @param index the current header to check
	 * @return filtered data.
	 */
	generateBenefits(data, index) {
		return data.filter(datum => datum.benefits[index] === 1);
	}

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TextMaskModule } from 'angular2-text-mask';

import { StripeModule } from '@i5/ngx-stripe';
import { LayoutModule } from '../layout';

import { PublicRoutingModule } from './public-routing.module';
import { HomeComponent } from './home/home.component';
import { MemberRegistrationComponent } from './registration/components/member-registration.component';
import { SponsorRegistrationComponent } from './registration/components/sponsor-registration.component';
import { BenefitsTableComponent } from '../public/benefits-table/benefits-table.component';
import { SponsorshipComponent } from '../public/sponsorship/sponsorship.component';
import { ContactComponent } from './contact/contact.component';
import { InfoComponent } from './info/info.component';
import { EventComponent } from './events/events.component';
import { ResourceListComponent } from './resources/components/resource-list.component';
import { ResourceService } from './resources/resource.service';
import { OverlayModule } from '@angular/cdk/overlay';
import { ResourceDownloadComponent } from './resources/components/resource-download.component';
import { ResourceRequestComponent } from './resources/components/resource-request.component';


/**
 * The public module houses all the components that are accessible without logging in.
 */
@NgModule({
	declarations: [
		BenefitsTableComponent,
		HomeComponent,
		ContactComponent,
		InfoComponent,
		EventComponent,
		MemberRegistrationComponent,
		ResourceDownloadComponent,
		ResourceListComponent,
		ResourceRequestComponent,
		SponsorRegistrationComponent,
		SponsorshipComponent,
	],
	imports: [
		PublicRoutingModule,

		CommonModule,
		FlexLayoutModule,
		LayoutModule,
		ReactiveFormsModule,
		StripeModule,
		TextMaskModule,
		OverlayModule,
	],
	providers: [
		ResourceService,
	],
	entryComponents: [
		ResourceRequestComponent,
	],
})
export class PublicModule { }

import { Component } from '@angular/core';


/**
 * The component for the home page.
 */
@Component({
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.scss']
})
export class HomeComponent {
	/**
	 * the table configuration object for the benefits table component
	 */
	table = {
		title: 'Benefits',
		formLink: '/member-registration',
		headers: [
			{name: 'Follower', description: 'Annual Subscription', price: 0, hidden: true, params: {level: 'membership-follower'} },
			{name: 'Member', description: 'Annual Subscription', price: 275, hidden: true, params: {level: 'membership-base'} },
			{name: 'Influencer', description: 'Annual Subscription', price: 1000, hidden: true, params: {level: 'membership-influencer'} },
			{name: 'Leader', description: 'Annual Subscription', price: 10000, hidden: true, params: {level: 'membership-leader'} },
		],
		data: [
			{
				name: 'C-Suite Manufacturing Council (CMC) Social Media Posts',
				benefits: [1, 1, 1, 1]
			},
			{
				name: 'CMC Informational Emails',
				benefits: [1, 1, 1, 1]
			},
			{
				name: 'CMC News Letter',
				benefits: [1, 1, 1, 1]
			},
			{
				name: 'Access to content created by the CMC',
				benefits: [0, 1, 1, 1]
			},
			{
				name: 'Invitation to CMC events',
				benefits: [0, 1, 1, 1]
			},
			{
				name: 'Invitation to CMC working groups and working group leadership',
				benefits: [0, 1, 1, 1]
			},
			{
				name: '** Invitation to CMC decision making meetings',
				benefits: [0, 1, 1, 1]
			},
			{
				name: 'Complimentary Invitation to National C-Suite Network Conferences',
				benefits: [0, 0, 1, 1]
			},
			{
				name: 'Admission to C-Suite Premier Digital & VIP In-Person Events',
				benefits: [0, 0, 1, 1]
			},
			{
				name: 'Four Free Books per Year from C-Suite Book Club',
				benefits: [0, 0, 1, 1]
			},
			{
				name: 'Discounts on Lifestyle Products & Gifts',
				benefits: [0, 0, 1, 1]
			},
			{
				name: 'Access to C-Suite Network Councils & Content',
				benefits: [0, 0, 1, 1]
			},
			{
				name: 'Access to Local Information & Members',
				benefits: [0, 0, 1, 1]
			},
			{
				name: 'Executive Retreat Programs',
				benefits: [0, 0, 1, 1]
			},
			{
				name: 'Monthly C-Suite Newsletter with Relevant Business Content',
				benefits: [0, 0, 1, 1]
			},
			{
				name: 'Personalized Concierge Services',
				benefits: [0, 0, 1, 1]
			},
			{
				name: 'Additional Services & Benefits Provided by C-Suite Network Partners',
				benefits: [0, 0, 1, 1]
			},
			{
				name: 'Hero Club (HC) Member Only Meetings Annually',
				benefits: [0, 0, 0, 1]
			},
			{
				name: 'HC Digital Meetings',
				benefits: [0, 0, 0, 1]
			},
			{
				name: 'Workshops, Coaching, and Training',
				benefits: [0, 0, 0, 1]
			},
			{
				name: 'Personalized Prioritization of Membership',
				benefits: [0, 0, 0, 1]
			},
			{
				name: 'Prioritization Meeting',
				benefits: [0, 0, 0, 1]
			},
			{
				name: '1 Month Follow-Up',
				benefits: [0, 0, 0, 1]
			},
			{
				name: 'HC Monthly Newsletter',
				benefits: [0, 0, 0, 1]
			},
			{
				name: 'Hero Club Council Facebook Page',
				benefits: [0, 0, 0, 1]
			},
			{
				name: 'Invitations to Hero Club City Summits Across North America',
				benefits: [0, 0, 0, 1]
			},
			{
				name: 'Personal Introductions',
				benefits: [0, 0, 0, 1]
			},
			{
				name: 'C-Suite Network Member Black Benefits',
				benefits: [0, 0, 0, 1]
			},
			{
				name: 'Platinum Services & Benefits Specific to Heroes',
				benefits: [0, 0, 0, 1]
			},
			{
				name: 'Other VIP Invitations & Opportunities',
				benefits: [0, 0, 0, 1]
			},
		]
	};
}

<div class="title-wrapper">
	<div class="title">
		<h4 class="sub-title">C-Suite Network Announces Launch of the Manufacturing Council</h4>
		<h4 class="sub-title">The Network launches Council to help manufacturing leaders innovate,
		improve, and thrive through thought leadership and working groups</h4>
	</div>
</div>
<div class="text-body">
	<p>
			New York, NY – March 5, 2019 -- The C-Suite Network, the world’s most trusted
			network of C-Suite leaders, is announcing today the launch of a new council,
			the C-Suite Network Manufacturing Council, chaired by Hero Club member and
			President and CEO of i5Services, Alan Davis. The Council is dedicated to
			help manufacturing leaders innovate, improve, thrive, and make a difference
			to advance the overall industry in the United States.
	</p>
	<p>
			The Council’s main purpose is to progress the manufacturing industry by tackling
			issues pertaining to the workforce, policy, sustainability, and supply chain that
			will have a lasting, positive impact. Through thought leadership and working
			groups, the Manufacturing Council is looking to initially provide solutions to
			the following problems:
	</p>
	<ul class="info-list">
		<li>
			<strong>Innovation</strong> &mdash; <span>Why is the US being out-innovated in the world marketplace
				and what do we do about it?</span>
		</li>
		<li>
			<strong>Workforce</strong> &mdash; <span>How do we solve the workforce deficit and change the perception
				of US manufacturing?</span>
		</li>
		<li>
			<strong>Policy</strong> &mdash; <span>How do we adapt to changing tariffs and government policies?</span>
		</li>
		<li>
			<strong>Supply chain</strong> &mdash; <span>How do we shorten the supply chain and connect with
			manufacturers closer to our facilities and why can't we find them today?</span>
		</li>
		<li>
			<strong>Process taxonomy</strong> &mdash; <span>Why is there no standard definition of manufacturing
			processes and how do we build and maintain one?</span>
		</li>
	</ul>
	<p>
		The C-Suite Network Manufacturing Council offers a number of benefits to its members,
		including access to content created by the Council, invitation to council events and
		working groups, networking opportunities with some of the greatest minds in the
		industry, and opportunities to help produce thought leadership content. Members may
		also opt for additional C-Suite benefits by becoming an influencer, leader or
		participant in Council decision making.
	</p>
	<p>The Coucil has four different ways to get involved:</p>
	<ul class="info-list">
		<li>
			<strong>Follower</strong> &mdash; <span>Receive newsletter, social media posts, and informational emails</span>
		</li>
		<li>
			<strong>Member</strong> &mdash; <span>Access to content created, invitations to events, and participation
			in working groups and decision-making meetings</span>
		</li>
		<li>
			<strong>Influencer</strong> &mdash; <span>Council membership, plus joining the C-Suite Network to expand
			interactions with thousands of influential leaders striving to make a difference</span>
		</li>
		<li>
			<strong>Leader</strong> &mdash; <span>Gain access to The Hero Club (must meet eligibility requirements),
			a group of dedicated leaders striving to make the world a better place for all</span>
		</li>
	</ul>
	<p>
		“As the Chairman and CEO of the C-Suite Network, I’m excited to announce the launch of
		the Manufacturing Council which will identify solutions to propel the industry forward”
		said Jeffrey Hayzlett, Chairman and CEO, C-Suite Network. “Alan is a veteran of the trade,
		who is one of the best and brightest minds and his dedication to the industry will be
		incredibly beneficial to every Council member.”
	</p>
	<p>
		The C-Suite Network’s continued mission is to provide members with the most up-to-date
		tools and benefits to help them stay abreast of all the changes taking place in the
		business world.
	</p>
	<p>
		“I am thrilled to have the opportunity to create and lead the Manufacturing Council,
		and become even more involved with the C-Suite Network,” said Davis. “Every executive
		I’ve met throughout the network has been top notch, and I can’t wait to start making
		a difference with this council and all of the C-Suite Network entities.”
	</p>
	<p>For more information, visit: <a href="https://www.c-suitenetwork.com">www.c-suitenetwork.com</a></p>
	<p>
		<span class="paragraph-header">About C-Suite Network</span>
		C-Suite Network is the world’s most trusted network of C-Suite leaders, with a focus
		on providing growth, development and networking opportunities for business executives
		with titles of vice president and above. The C-Suite Network’s mission is to provide
		a peer community, networking events, relevant content and services to support c-level
		executives and other entrepreneurs achieve professional success.
	</p>
	<p>
		C-Suite Network offers invitation-only events as well as custom-tailored content
		through all its entities: C-Suite TV, C-Suite Radio, C-Suite Book Club, and
		C-Suite Network Advisors™. Learn more at <a href="https://www.c-suitenetwork.com">www.c-suitenetwork.com</a>,
		or connect on <a href="https://www.linkedin.com/company/the-c-suite-network">LinkedIn</a>,
		<a href="https://twitter.com/csuitenetwork">Twitter</a> and
		<a href="https://www.facebook.com/thecsuitenetwork">Facebook</a>.
	</p>
	<p class="contact-block">
		<span>Media Contact:</span>
		<span>Keira Rodriguez</span>
		<span>Senior Media Stategist</span>
		<span>TallGrass Public Relations</span>
		<span>keira.rodriguez@tallgrasspr.com</span>
		<span>914.830.3241</span>
	</p>
</div>

import { Component, HostBinding, Input, Optional } from '@angular/core';
import { ErrorGroupComponent } from './error-group.component';


/**
 * Represents a single error message for a form control.
 *
 * This should be nested in a `mc-form-field-errors` tag.
 */
@Component({
	selector: 'mc-form-field-error',
	template: `<ng-content></ng-content>`,
	styleUrls: ['error-message.component.scss'],
})
export class ErrorMessageComponent {
	/**
	 * The rule to use for this error message. This should be the key in the `control.errors` object that we want to
	 * use.
	 */
	@Input() rule: string;
	/**
	 * Toggle the `show` class on and off based on the existance of the error.
	 */
	@HostBinding('class.show') get show(): boolean {
		const controlErrors = this.group.control ? this.group.control.errors : null;
		return (controlErrors && this.rule) ? controlErrors[this.rule] : false;
	}

	/**
	 * The constructor.
	 *
	 * @param group The group this component is in.
	 */
	constructor(@Optional() private group: ErrorGroupComponent) {
		if (!group) {
			throw new Error('mc-form-field-error must be a child of mc-form-field-errors');
		}
	}
}

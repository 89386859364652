<div class="title-wrapper">
	<div class="title">
		<h4 class="sub-title">Events:</h4>
	</div>
</div>
<div class="event-flex">
	<div class="event-details">
		<div id="eventbrite-widget-container-{{ eventId }}"></div>
	</div>
	<div class="calendar">
		<div style="width:195px; text-align:center;" >
			<iframe
				[src]="calendarUrl"
				frameborder="0"
				height="546"
				width="195"
				marginheight="0"
				marginwidth="0"
				scrolling="no"
				allowtransparency="true"
			>
			</iframe>
			<div
				style="font-family:Helvetica, Arial; font-size:12px; padding:10px 0 5px; margin:2px; width:195px; text-align:center;" >
				<a
					class="powered-by-eb"
					style="color: #ADB0B6; text-decoration: none;"
					target="_blank"
					href="http://www.eventbrite.com/"
				>
					Powered by Eventbrite
				</a>
			</div>
		</div>
	</div>
</div>

<mc-card *ngIf='!paymentSucceeded;else thanks'>
	<mc-card-header>
		<h3>Member Registration</h3>
	</mc-card-header>
	<mc-card-body>
		<form [formGroup]='form'>
			<mc-form-field>
				<label>Name</label>
				<input type='text' formControlName='name' />
				<mc-form-field-errors [control]='form.controls.name'>
					<mc-form-field-error rule='required'>This field is required.</mc-form-field-error>
				</mc-form-field-errors>
			</mc-form-field>
			<mc-form-field>
				<label>Email</label>
				<input type='email' formControlName='email' />
				<mc-form-field-errors [control]='form.controls.email'>
					<mc-form-field-error rule='required'>This field is required.</mc-form-field-error>
					<mc-form-field-error rule='email'>This is an invalid email address.</mc-form-field-error>
				</mc-form-field-errors>
			</mc-form-field>
			<mc-form-field>
				<label>Phone</label>
				<input type='tel' [textMask]="phoneMask" formControlName='phone' />
				<mc-form-field-errors [control]='form.controls.phone'>
					<mc-form-field-error rule='required'>This field is required.</mc-form-field-error>
				</mc-form-field-errors>
			</mc-form-field>
			<mc-form-field>
				<label>Membership Level</label>
				<mc-select formControlName='membershipLevel'>
					<option *ngFor='let level of levels' [value]='level.id'>{{ level.name }} {{ level.amount | currency:USD:symbol:'0.0-2' }}</option>
				</mc-select>
			</mc-form-field>
			<mc-form-field>
				<mc-checkbox formControlName='decisionMakingTeam'>
					Council Decision Making Team ($500)
				</mc-checkbox>
			</mc-form-field>
			<mc-form-field>
				<label>Company</label>
				<input type='text' formControlName='company' />
				<mc-form-field-errors [control]='form.controls.company'>
					<mc-form-field-error rule='required'>This field is required.</mc-form-field-error>
				</mc-form-field-errors>
			</mc-form-field>
			<mc-form-field>
				<label>What is your title?</label>
				<mc-select formControlName='title'>
					<option *ngFor='let title of titles'>{{ title }}</option>
					<option *ngIf="membershipLevel.allowOther">Other</option>
				</mc-select>
				<mc-form-field-errors [control]='form.controls.title'>
					<mc-form-field-error rule='required'>This field is required.</mc-form-field-error>
				</mc-form-field-errors>
			</mc-form-field>
			<mc-form-field>
				<label>What is your annual business revenue?</label>
				<mc-select formControlName='revenue'>
					<option *ngFor='let revenue of revenues'>{{ revenue }}</option>
				</mc-select>
				<mc-form-field-errors [control]='form.controls.revenue'>
					<mc-form-field-error rule='required'>This field is required.</mc-form-field-error>
				</mc-form-field-errors>
			</mc-form-field>
			<mc-form-field>
				<label>How many employees do you have?</label>
				<mc-select formControlName='employees'>
					<option *ngFor='let employee of employees'>{{ employee }}</option>
				</mc-select>
				<mc-form-field-errors [control]='form.controls.employees'>
					<mc-form-field-error rule='required'>This field is required.</mc-form-field-error>
				</mc-form-field-errors>
			</mc-form-field>
			<mc-form-field *ngIf='total > 0'>
				<label>Payment Information</label>
				<stripe-element formControlName='cardInfo' #stripeComponent
					[fonts]='stripeFonts' [style]='stripeStyle' [classes]='stripeClasses'></stripe-element>
				<mc-form-field-errors [control]='form.controls.cardInfo'>
					<mc-form-field-error rule='required'>This field is required.</mc-form-field-error>
					<mc-form-field-error rule='incomplete_number'>Incomplete card number.</mc-form-field-error>
					<mc-form-field-error rule='invalid_number'>Invalid card number.</mc-form-field-error>
					<mc-form-field-error rule='incomplete_expiry'>Incomplete expiry.</mc-form-field-error>
					<mc-form-field-error rule='invalid_expiry_year_past'>This card is expired.</mc-form-field-error>
					<mc-form-field-error rule='invalid_expiry_month_past'>This card is expired.</mc-form-field-error>
					<mc-form-field-error rule='incomplete_cvc'>The CVC number is incomplete.</mc-form-field-error>
					<mc-form-field-error rule='incomplete_zip'>The zip code is incomplete.</mc-form-field-error>
				</mc-form-field-errors>
			</mc-form-field>
			<mc-form-field>
				<mc-checkbox formControlName='newsletter'>Opt-in to our email communication to receive information about our events, insight, news and platform updates.</mc-checkbox>
				<mc-alert *ngIf='form.controls.newsletter && !form.controls.newsletter.value' [type]='form.controls.newsletter.errors ? "error" : "info"'>
					Receiving emails about developments in the industry and events is one of the primary benefits of
					membership in the council. Please check the box above to stay in the loop.
				</mc-alert>
			</mc-form-field>
		</form>
		<div *ngIf='total > 0'>
			<h4 style='text-align:center;'>Summary</h4>
			<div *ngFor='let item of lineItems' style='display:flex;flex-direction:row;margin:1em 0;'>
				<div>{{ item.description }}</div>
				<div style='flex:auto'></div>
				<div>{{ item.amount | currency:USD:symbol:'0.0-2' }}</div>
			</div>
			<div style='display:flex;flex-direction:row;font-weight:bold;'>
				<div>Total (Annual)</div>
				<div style='flex:auto'></div>
				<div>{{ total | currency:USD:symbol:'0.0-2' }}</div>
			</div>
		</div>
	</mc-card-body>
	<mc-card-footer>
		<button type='submit' [disabled]='!form.valid || buttonLoading' [class.loading]='buttonLoading' (click)='join()'>Join</button>
	</mc-card-footer>
</mc-card>

<ng-template #thanks>
	<mc-card>
		<mc-card-header>
			<h3>Thank you for joining our team!</h3>
		</mc-card-header>
		<mc-card-body>
			<p>
				Check your inbox for an email with payment receipt and information on what you can expect as a member of
				the Manufacturing Council. If it hasn't arrived yet, it should arrive within a minute or two.
			</p>
		</mc-card-body>
		<mc-card-footer>
			<a class='btn' routerLink='/home'>Return to the home page</a>
		</mc-card-footer>
	</mc-card>
</ng-template>

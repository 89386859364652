import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';

import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

import { Angulartics2Module } from 'angulartics2';

import { StripeModule } from '@i5/ngx-stripe';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PublicModule } from './public/public.module';
import { LayoutModule } from './layout/layout.module';

import { environment } from '../environments/environment';

/**
 * The application module.
 */
@NgModule({
	declarations: [
		AppComponent
	],
	imports: [
		// Modules with routing
		PublicModule,

		// Other modules
		Angulartics2Module.forRoot(),
		BrowserModule,
		FlexLayoutModule,
		HttpClientModule,
		LayoutModule,
		StripeModule.forRoot({ key: environment.stripePublishableKey }),
		FontAwesomeModule,
		RecaptchaV3Module,

		// App routing module
		AppRoutingModule
	],
	bootstrap: [AppComponent],
	providers: [
		{ provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.recaptcha },
	],
})
export class AppModule {
	constructor(private library: FaIconLibrary) {
		library.addIcons(faBars);
	}
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MemberRegistrationData, SponsorRegistrationData } from './interfaces';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';


/**
 * The registration service.
 *
 * Contains methods to manage registration from the public module.
 */
@Injectable({
	providedIn: 'root',
})
export class RegistrationService {
	/**
	 * The constructor.
	 *
	 * @param http The http client.
	 */
	constructor(private http: HttpClient) { }

	/**
	 * Register a member.
	 *
	 * @param data The registration data.
	 * @return An observable of the server response.
	 */
	registerMember(data: MemberRegistrationData): Observable<any> {
		return this.http.post(`${environment.api}/mfg-council/registration/register-member`, data);
	}

	/**
	 * Register a sponsor.
	 *
	 * @param data The registration data.
	 * @return An observable of the server response.
	 */
	registerSponsor(data: SponsorRegistrationData): Observable<any> {
		return this.http.post(`${environment.api}/mfg-council/registration/register-sponsor`, data);
	}
}

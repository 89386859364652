<!-- table for larger screens -->
<table fxHide fxShow.gt-sm>
	<thead>
		<tr>
			<th class="table-title">{{ title }}</th>
			<th *ngFor="let header of headers" class='benefit-column-header'>
				<div class="benefit-column-title">
					<span>{{ header.name }}</span>
				</div>
				<div class="benefit-column-body">
					<p class="benefit-body-description">{{ header.description }}</p>
					<span class="benefit-body-price">{{ header.price | currency:USD:symbol:'0.0-2' }}</span>
					<div class="buy-button">
						<a [routerLink]="link" [queryParams]="header.params" class='btn'>
							Join Now
						</a>
					</div>
				</div>
			</th>
		</tr>
	</thead>
	<tbody>
		<tr *ngFor="let b of data">
			<td>{{ b.name }}</td>
			<td *ngFor="let x of b.benefits">
				<div *ngIf="x === 1" class="has-benefit"></div>
			</td>
		</tr>
	</tbody>
</table>

<!-- divs for smaller screens -->
<div *ngFor="let header of headers; index as i" fxShow fxHide.gt-sm class='small-benefits-section'>
	<div class="benefit-column-title" (click)="header.hidden = !header.hidden;">
		<span>{{ header.name }}</span>
	</div>
	<div *ngIf="!header.hidden" class="small-benefits-body">
		<div class="benefit-column-body">
			<p class="benefit-body-description">{{ header.description }}</p>
			<span class="benefit-body-price">{{ header.price | currency:USD:symbol:'0.0-2' }}</span>
			<div class="buy-button">
				<a [routerLink]="link" [queryParams]="header.params" class='btn'>
					Join Now
				</a>
			</div>
		</div>
		<div>
			<ul class="info-list">
				<li *ngFor="let b of generateBenefits(data, i)">{{ b.name }}</li>
			</ul>
		</div>
	</div>
</div>

<!-- Footnotes -->
<div class='footnotes' [ngStyle.gt-sm]='{width:"70%"}'>
	<div class='footnote'>
		<label>**</label>
		<p>
			Be a part of the C-Suite Manufacturing Council decision-making team. This requires a $500 annual fee in
			addition to your membership. Decide the topics of discussion, membership requirements, strategy and
			direction of the council, what is published, etc.
		</p>
	</div>
</div>

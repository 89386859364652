import { Component, HostBinding, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';


/**
 * Represents a group of field error messages.
 */
@Component({
	selector: 'mc-form-field-errors',
	template: '<ng-content></ng-content>',
	styleUrls: ['error-group.component.scss'],
})
export class ErrorGroupComponent {
	/**
	 * The control this error group is for.
	 */
	@Input() control: AbstractControl;
	/**
	 * Toggle the `show` class on and off based on the control validity.
	 */
	@HostBinding('class.show') get show(): boolean {
		return this.control ? (this.control.touched && this.control.invalid) : false;
	}
}

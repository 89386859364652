import { Component, forwardRef, OnDestroy } from '@angular/core';
import { ControlValueAccessor , NG_VALUE_ACCESSOR, FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';


/**
 * Wraps a checkbox input and label for custom styling and event handling..
 */
@Component({
	selector: 'mc-checkbox',
	templateUrl: './checkbox.component.html',
	styleUrls: ['./checkbox.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => CheckboxComponent),
			multi: true
		}
	],
})
export class CheckboxComponent implements ControlValueAccessor, OnDestroy {
	/**
	 * The form control to use for the internal checkbox element.
	 */
	public control: FormControl = new FormControl('');
	/**
	 * The registered onChange function. See `registerOnChange()`.
	 */
	private onChange: (val: any) => void;
	/**
	 * The registered onTouched function. See `registerOnTouched()`.
	 */
	private onTouched: () => void;
	/**
	 * Subscriptions that need to be unsubscribed from.
	 */
	private unsubscribeMe: Subscription[] = [];


	/**
	 * The constructor.
	 */
	constructor() {
		// Forward events from the internal checkbox control.
		const subscription = this.control.valueChanges.subscribe(val => {
			this.onChange(val);
			this.onTouched();
		});
		this.unsubscribeMe.push(subscription);
	}

	/**
	 * Unsubscribe from pending subscriptions.
	 */
	ngOnDestroy() {
		this.unsubscribeMe.forEach((s: Subscription) => s.unsubscribe());
	}

	/**
	 * Write the value to the control.
	 *
	 * @param val The value to write.
	 */
	writeValue(val: any): void {
		this.control.setValue(val, {onlySelf: true, emitEvent: false});
	}

	/**
	 * Register the onChange function.
	 *
	 * @param fn The onChange function.
	 */
	registerOnChange(fn: (val: any) => void): void {
		this.onChange = fn;
	}

	/**
	 * Register the onTouched function.
	 *
	 * @param fn The onTouched function.
	 */
	registerOnTouched(fn: () => void): void {
		this.onTouched = fn;
	}

	/**
	 * Register the onTouched function.
	 *
	 * @param fn The onTouched function.
	 */
	setDisabledState(isDisabled: boolean): void {
		if (isDisabled) {
			this.control.disable({onlySelf: true, emitEvent: false});
		} else {
			this.control.enable({onlySelf: true, emitEvent: false});
		}
	}
}

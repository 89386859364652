import { Component } from '@angular/core';

/**
 * Represents a styled card element.
 */
@Component({
	selector: 'mc-card',
	template: '<ng-content></ng-content>',
	styleUrls: ['./card.component.scss'],
})
export class CardComponent { }
